import React, { useState, useEffect } from 'react';
import './Header.css';
import { FaTimes } from 'react-icons/fa';
import { useAddress } from '../../AddressContext/AddressContext';
import axios from 'axios';
import { Form, useNavigate } from 'react-router-dom';
import {DateTime} from 'luxon';

const Header = () => {
  const { address, setAddress } = useAddress();
  const [showDeliveryCard, setShowDeliveryCard] = useState(false);
  const [showTakeawayCard, setShowTakeawayCard] = useState(false);
  
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
      
  const [selectedTime, setSelectedTime] = useState(getCurrentTime());
  const [distance, setDistance] = useState(null);
  const [error, setError] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [eligibilityMessage, setEligibilityMessage] = useState('');
  const [isGrayedOut, setIsGrayedOut] = useState(false);
  const [deliveryStartPrice, setDeliveryStartPrice] = useState(0);
  const navigate = useNavigate(); // Use useNavigate for navigation
  const BASE_URL = window.env.REACT_APP_BASE_URL;
  const storeId = window.env.REACT_APP_STORE_ID;
  const jwt = localStorage.getItem('jwt');
  const [minTime, setMinHour] = useState(null);
  const [maxTime, setMaxHour] = useState(null);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const url = `${BASE_URL}parameter-store/?store_id=${storeId}`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwt}`
          },
          withCredentials: true
        });
        setDeliveryStartPrice(res.data.delivery_start_price);
        setMinHour(DateTime.fromFormat(res.data.open_hours, "HH:mm:ss").toFormat("HH:mm"))
        setMaxHour(DateTime.fromFormat(res.data.close_hours, "HH:mm:ss").toFormat("HH:mm"))
      } catch (error) {
        console.error('Error fetching:', error);
      }
    };

    fetchStore();
  }, []);

  const handleDeliveryClick = () => {
    if (activeButton === 'delivery') {
      setShowDeliveryCard(false);
      setActiveButton(null);
    } else {
      setShowDeliveryCard(true);
      setShowTakeawayCard(false);
      setActiveButton('delivery');
    }
  };

  const handleTakeawayClick = () => {
    if (activeButton === 'takeaway') {
      setShowTakeawayCard(false);
      setActiveButton(null);
    } else {
      setShowTakeawayCard(true);
      setShowDeliveryCard(false);
      setActiveButton('takeaway');
    }
  };

  const handleCloseCard = () => {
    setShowDeliveryCard(false);
    setShowTakeawayCard(false);
    setDistance(null);
    setError('');
    setSuggestions([]);
    setEligibilityMessage('');
    setActiveButton(null);
    setIsGrayedOut(false);
  };

  const handleSearchClick = async () => {
    localStorage.removeItem('take_away_time');

    if (!storeId) {
      setError('Aucun storeId trouvé');
      setDistance(null);
      return;
    }

    try {
      const order_time = getCurrentTime();
      const response = await fetch(`${BASE_URL}get_eligibility/?store_id=${storeId}&destination=${address}&order_time=${order_time}`);

      if (!response.ok) {
        throw new Error('Une erreur est survenue lors de la requête.');
      }

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        setDistance(null);
        setEligibilityMessage('');
        setIsGrayedOut(false);
      } else if (data.message) {
        if (data.message === 'Delivery is closed at the moment.') {
          setEligibilityMessage('La livraison est fermée pour le moment.');
        } else if (data.message === 'The store is closed at the moment.') {
          setEligibilityMessage('Le magasin est fermé pour le moment.');
        } else {
          setEligibilityMessage('');
        }
        setError(null);
        setIsGrayedOut(false);
      } else {
        setDistance(data.distance);
        setError('');

        if (data.is_eligible) {
          setEligibilityMessage('Votre adresse a été bien enregistrée.');
          setIsGrayedOut(true);
          setAddress(address);  // Save the eligible address to context
          localStorage.setItem('address', address);
          navigate('/menus'); // Navigate to the "menus" page
        } else {
          setEligibilityMessage('Désolé, la livraison n\'est pas disponible pour les adresses . Cependant, vous pouvez nous contacter par téléphone pour organiser une livraison spéciale. Merci !');
          setIsGrayedOut(false);
        }
      }
    } catch (error) {
      setError(error.message);
      setDistance(null);
      setEligibilityMessage('');
      setIsGrayedOut(false);
    }
  };

  const handleInputChange = async (e) => {
    setAddress(e.target.value);

    try {
      const response = await fetch(`${BASE_URL}get-address-suggestions/?input=${encodeURIComponent(e.target.value)}`);

      if (!response.ok) {
        throw new Error('Une erreur est survenue lors de la requête.');
      }

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        setSuggestions([]);
      } else {
        setSuggestions(data.suggestions);
        setError('');
      }
    } catch (error) {
      setError(error.message);
      setSuggestions([]);
    }
  };

  const handleTakeAway = () => {
    localStorage.setItem('take_away_time', selectedTime);
    setAddress('')
    localStorage.setItem('address','');
    setIsGrayedOut(true);
    navigate('/menus');
  }

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const [hours, minutes] = selectedTime.split(':').map(Number);
    const [minHour, minMinutes] = minTime.split(':').map(Number);
    const [maxHour, maxMinutes] = maxTime.split(':').map(Number);

    // Convert times to minutes from midnight
    const selectedTimeInMinutes = hours * 60 + minutes;
    const minTimeInMinutes = minHour * 60 + minMinutes;
    const maxTimeInMinutes = maxHour * 60 + maxMinutes;

    // Determine if the closing time is after midnight
    const closingAfterMidnight = maxTimeInMinutes < minTimeInMinutes;

    let isInvalid = false;

    if (closingAfterMidnight) {
        // Check if the selected time is in the interval between closing and opening hours
        isInvalid = selectedTimeInMinutes > maxTimeInMinutes && selectedTimeInMinutes < minTimeInMinutes;
    } else {
        // Normal case where closing time is on the same day
        isInvalid = selectedTimeInMinutes < minTimeInMinutes || selectedTimeInMinutes > maxTimeInMinutes;
    }

    if (isInvalid) {
        alert("Veuillez sélectionner une heure entre " + minTime + " et " + maxTime);
        return;
    }

    setSelectedTime(selectedTime);
  };
  
  return (
    <div className='header'>
      <div className="header-contents">
        <h2>Commandez vos plats préférés ici</h2>
        <p>Commandez dès maintenant chez Royal Naan pour découvrir nos délicieux plats. Des saveurs uniques vous attendent, prêtes à être dégustées !</p>
        <div className='btns flex'>
          <button className={`actionBtn ${activeButton === 'delivery' ? 'active' : ''}`} onClick={handleDeliveryClick}>
            En livraison
          </button>
          <button className={`actionBtn ${activeButton === 'takeaway' ? 'active' : ''}`} onClick={handleTakeawayClick}>
            À emporter
          </button>
        </div>
      </div>

      {showDeliveryCard && (
        <div className={`delivery-card ${isGrayedOut ? 'grayed-out' : ''}`}>
          <div className='homeCard'>
            <div className='inputGroup'>
            <div className='inputItem'>
                  <label className="address-label">
                      Adresse 
                      <span className="address-price">Livraison à partir de {deliveryStartPrice} €</span>
                  </label>
                  <input 
                      type='text' 
                      value={address}
                      onChange={handleInputChange}
                      placeholder='Entrez votre adresse'
                  />
                  {suggestions.length > 0 && (
                      <ul className="suggestion-list">
                          {suggestions.map((suggestion, index) => (
                              <li key={index} onClick={() => { setAddress(suggestion); setSuggestions([]); }}>
                                  {suggestion}
                              </li>
                          ))}
                      </ul>
                  )}
              </div>

              <div className='buttonContainer'>
                <button className='actionBtn' onClick={handleSearchClick}>Rechercher</button>
              </div>
            </div>
            {error && <p className='error'>{error}</p>}
            {eligibilityMessage && <p className='message error'>{eligibilityMessage}</p>}
            <FaTimes className='close-icon' onClick={handleCloseCard} />
          </div>
        </div>
      )}

      {showTakeawayCard && (
        <div className="takeaway-card">
          <div className='homeCard'>
            <div className='inputGroup'>
              <div className='inputItem'>
                <label>Heure de retrait</label>
                <input 
                  type='time' 
                  value={selectedTime}
                  onChange={handleTimeChange}
                />
              </div>
              <div className='buttonContainer'>
                <button className='actionBtn' onClick={handleTakeAway}>Commander</button>
              </div>
            </div>
            <FaTimes className='close-icon' onClick={handleCloseCard} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
