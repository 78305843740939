import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { FaShoppingCart, FaTimes } from 'react-icons/fa';
import logo from './logo.jpg';
import { Link } from 'react-router-dom'; 
import { useCart } from '../../CartContext/CartContext';
import { scroller } from 'react-scroll';

const Navbar = ({ showCart = true }) => {
  const [menu, setMenu] = useState('Acceuil');
  const [transparent, setTransparent] = useState('navbar');
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600); // Détecte la taille de l'écran
  const { cartCount } = useCart();

  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent('navbar activeNavbar');
    } else {
      setTransparent('navbar');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', addBg);
    window.addEventListener('resize', () => setIsMobile(window.innerWidth <= 600)); // Met à jour isMobile sur redimensionnement
    return () => {
      window.removeEventListener('scroll', addBg);
      window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 600));
    };
  }, []);

  const handleNavigation = (section) => {
    setMenu(section);
    setShowSidebar(false);

    if (section === 'Acceuil') {
      scroller.scrollTo('header', {
        duration: 500,
        smooth: 'easeInOutQuart'
      });
    } else if (section === 'Contact') {
      scroller.scrollTo('footer', {
        duration: 500,
        smooth: 'easeInOutQuart'
      });
    }
  };

  const toggleSidebar = () => {
    if (isMobile) {
      setShowSidebar(!showSidebar);
    }
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className={transparent}>
      <img src={logo} alt="Logo" className='logo' onClick={toggleSidebar} />
      
      <div className="navbar-menu">
        <ul>
          <li onClick={() => handleNavigation('Acceuil')} className={menu === 'Acceuil' ? 'active' : ''}>
            <Link to="#header" smooth={true} duration={500}>
              Acceuil
            </Link>
          </li>
          <li onClick={() => handleNavigation('Contact')} className={menu === 'Contact' ? 'active' : ''}>
            <Link to="#footer" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {isMobile && ( 
        <div className={`sidebar ${showSidebar ? 'active' : ''}`}>
          <FaTimes className="close-icon" onClick={closeSidebar} />
          <ul className="sidebar-menu">
            <li onClick={() => handleNavigation('Acceuil')} className={menu === 'Acceuil' ? 'active' : ''}>
              <Link to="#header" smooth={true} duration={500}>
                Acceuil
              </Link>
            </li>
            <li onClick={() => handleNavigation('Contact')} className={menu === 'Contact' ? 'active' : ''}>
              <Link to="#footer" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}

      <div className='navbar-right'>
        {showCart && (
          <div className='cart'>
            <Link to="/cart" className='cart'>
              <FaShoppingCart className='icon' />
              {cartCount > 0 && <span className='cart-count'>{cartCount}</span>}
            </Link>
          </div>
        )}
      </div>
      {showSidebar && <div className="backdrop" onClick={closeSidebar}></div>}
    </div>
  );
};

export default Navbar;
