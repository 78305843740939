import React from 'react';
import './Footer.css'; 
import logo from './halal.png'; 

const Footer = () => {
  const encodedAddress = encodeURIComponent('24 Bd Sylvain Dumon, 47000 Agen, France');
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="contact-info">
          <h3>Contactez nous :</h3> 
          <p><strong>Téléphone:</strong> 05 53 98 47 92</p>
          <p><strong>Email:</strong> contact@royalnaan.fr</p>
          <p>
            <strong>Adresse:</strong> 
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="address-link">
              24 Bd Sylvain Dumon, 47000 Agen, France
            </a>
          </p>
          <p><strong>Horaire d'ouverture:</strong>Lundi - Dimanche 11:00-23:00</p>
        </div>
      </div>
      <hr />
      <p className="copyright">© 2024 All rights reserved</p>
    </footer>
  );
}

export default Footer;
